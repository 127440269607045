import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';
import Currency from 'components/Currency';

import {makeStyles, Theme} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {Typography, IconButton, Badge, Box} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

// Icons
import CloseIcon from '@material-ui/icons/Close';

import {GET_CART, Cart} from 'operations/queries/getCart';
import {DELETE_CART_LINE} from 'operations/mutations/deleteCartLine';

import {useTranslation} from 'react-i18next';

import ConfirmationMessage from 'components/GlobalMessages/components/Messages/ConfirmationMessage';
import NoResults from 'components/NoResults';
import ProductMiniCartItem from './ProductMiniCartItem';
import SaveNewCartDialog from 'pages/my-account/sub-pages/saved-carts/components/saveNewCartDialog';
import { PDFDocument, StandardFonts, rgb} from 'pdf-lib';

interface Props {
    className?: string;
}

const MiniCart: React.FC<Props> = (props) => {
    const {data, loading, error} = useQuery<{ cart: Cart }>(GET_CART);
    const classes = useStyles(props);
    const {t} = useTranslation();
    const [openSaveCartDialog, setSavedCartDialog] = useState(false);
    const [state, setState] = useState({
        miniCartOpen: false,
    });

    const [removeCartLine] = useMutation(DELETE_CART_LINE);

    const [productToDelete, setProductToDelete] = useState<any>(null);

    const handleCloseSaveCart = (bool:boolean) => {
        setSavedCartDialog(bool);
        setState({
            miniCartOpen: false,
        });
    }


    const handleSetItemToDelete = async (cartLine: {
        productId: string;
        listPrice: number;
        name: string;
        amount: number;
        quantity: number;
    }) => {
        setProductToDelete(cartLine);
    };

    const handleDeleteItem = async (productId: string) => {
        setProductToDelete(null);
        await removeCartLine({
            variables: {
                input: {
                    productId: productId
                }
            },
        });
    };

    const toggleDrawer = () => (event: any) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({miniCartOpen: !state.miniCartOpen});
    };

    const getTotalQuantity = (cart: any) => (cart && cart.itemCount) || 0;

    if (loading || error) {
        return null;
    }
   
    const cart = data?.cart || ({} as any);
    // Function to format the current date and time
    const getFormattedDateTime = () => {
            const now = new Date();
            const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            };
            return now.toLocaleDateString('en-US', options);
    };

    const formatNumber = (number: number) => {
        return new Intl.NumberFormat('en-US', { 
          style: 'decimal', 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        }).format(number);
    };
    
    const exportPdf = async () => {
            // Create a new PDF document
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage();
            const { width, height } = page.getSize();
            // Embed the Times Roman font
            const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
            const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
            let yPosition = height - 50;  // Starting vertical position
            let xPosition = width * 0.05;  // Starting vertical position
            const dateTimeString = getFormattedDateTime(); // Get the current date and time
            // Title
            page.drawText(`Open Cart Export: ${dateTimeString}`, {x: xPosition, y: yPosition, font: timesRomanFontBold, size: 12});
            yPosition -= 40;
            // Overview
            if(cart.addresses[0].address1){
                page.drawText(`Shipping Address`, {x: xPosition, y: yPosition, font: timesRomanFontBold, size: 12});
                page.drawText(`${cart.addresses[0].address1}`, {x: xPosition, y: yPosition-20, font: timesRomanFont, size: 12});
                page.drawText(`${cart.addresses[0].city}, ${cart.addresses[0].stateName}, ${cart.addresses[0].postalCode}`, {x: xPosition, y: yPosition-40, font: timesRomanFont, size: 12});
                page.drawText(`${cart.addresses[0].countryName}`, {x: xPosition, y: yPosition-60, font: timesRomanFont, size: 12});
                yPosition -= 80;
            }
            // Table Headers
            page.drawText("Items", {x: xPosition, y: yPosition, font: timesRomanFontBold, size: 12});
            page.drawText("Sku", {x: xPosition + 300, y: yPosition, font: timesRomanFontBold, size: 12});
            page.drawText("Quantity", {x: xPosition + 375, y: yPosition, font: timesRomanFontBold, size: 12});
            page.drawText("Price", {x: xPosition + 440, y: yPosition, font: timesRomanFontBold, size: 12});
            page.drawText("Total", {x: xPosition + 490, y: yPosition, font: timesRomanFontBold, size: 12});
            page.drawRectangle({x: xPosition, y: yPosition - 5, width: (width*0.9), height: 0.5, color: rgb(0, 0, 0)});
            yPosition -= 20;
            // Line Items
            cart.lines.forEach((cartLine: any) => {
                if(cartLine.name.length > 60){
                page.drawText(`${cartLine.name.substring(0,59)}-`, {
                    x: xPosition,
                    y: yPosition,
                    font: timesRomanFont,
                    size: 10
                });
                page.drawText(`${cartLine.name.substring(59,cartLine.name.length)}`, {
                    x: xPosition,
                    y: yPosition - 10,
                    font: timesRomanFont,
                    size: 10
                });
                }else{
                page.drawText(`${cartLine.name}`, {
                    x: xPosition,
                    y: yPosition,
                    font: timesRomanFont,
                    size: 10
                });
                }
                page.drawText(`${cartLine.code}`, {
                x: xPosition + 300,
                y: yPosition,
                font: timesRomanFont,
                size: 10
                });
                page.drawText(`${cartLine.quantity}`, {
                x: xPosition + 375,
                y: yPosition,
                font: timesRomanFont,
                size: 10
                });
                page.drawText(`$${formatNumber(cartLine.listPrice)}`, {
                x: xPosition + 440,
                y: yPosition,
                font: timesRomanFont,
                size: 10
                });
                page.drawText(`$${formatNumber(cartLine.amount)}`, {
                x: xPosition + 490,
                y: yPosition,
                font: timesRomanFont,
                size: 10
                });
                yPosition -= 20;
            }); 
            page.drawRectangle({x: xPosition, y: yPosition - 5, width: (width*0.9), height: 0.5, color: rgb(0, 0, 0)});
            page.drawText("Sub Total", {x: xPosition + 375, y: yPosition-20, font: timesRomanFontBold, size: 12});
            page.drawText(`$${formatNumber(cart.subtotal)}`, {x: xPosition + 475, y: yPosition-20, font: timesRomanFont, size: 12});
            page.drawText("Estimated Total", {x: xPosition + 375, y: yPosition-40, font: timesRomanFontBold, size: 12});
            page.drawText(`$${formatNumber(cart.total)}`, {x: xPosition + 475, y: yPosition-40, font: timesRomanFont, size: 12});
            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            console.log(pdfBytes);
            // Download the PDF
            downloadPdf(pdfBytes);
    };

    const downloadPdf = (pdfBytes: any) => {
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'OrderExport.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const cart = (data && data.cart) || { lines: [] };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Typography>
                <IconButton
                    onClick={toggleDrawer()}
                    color="inherit"
                >
                    <Badge badgeContent={getTotalQuantity(data?.cart)}>
                        <ShoppingCartIcon/>
                    </Badge>
                </IconButton>
            </Typography>

            <div className={`${classes.root} ${props.className || ''}`}>
                <SwipeableDrawer
                    anchor="right"
                    open={state.miniCartOpen}
                    onOpen={toggleDrawer()}
                    onClose={toggleDrawer()}
                    className={classes.menuDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar className={classes.toolbar} disableGutters>
                        <IconButton onClick={toggleDrawer()} color="inherit">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h5">Cart</Typography>
                        <div className={classes.stretch}/>
                        <Typography variant="h6">
                            Total:{' '}
                            <strong>
                                <Currency value={data?.cart?.subtotal}/>
                            </strong>
                        </Typography>
                        &nbsp;
                    </Toolbar>
                    {data?.cart?.lines?.length ?
                        (
                            <>
                                <div className={`${classes.buttonWrapper} ${classes.desktopButton}`}>
                                    <Link
                                        component={RouterLink}
                                        to="/cart"
                                        onClick={() => {
                                            setState({miniCartOpen: false});
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                        >
                                            View Cart
                                        </Button>
                                    </Link>
                                </div>
                                <Divider/>
                            </>) : null}
                    <div className={classes.content}>
                        {data?.cart?.lines?.map((cartLine: any, i: number) => (
                            <ProductMiniCartItem
                                key={cartLine.productId}
                                cartLine={cartLine}
                                onDelete={handleSetItemToDelete}
                                setState={setState}
                            />
                        ))}
                        {!data || (data && !(data?.cart?.lines?.length > 0)) ? (
                            <NoResults icon="cart">Your cart is empty</NoResults>
                        ) : null}
                    </div>
                    {data?.cart?.lines?.length ? (
                        <>
                            <Divider/>
                            <div className={classes.buttonWrapper}>
                                <Link
                                    component={RouterLink}
                                    to="/checkout"
                                    onClick={() => {
                                        setState({miniCartOpen: false});
                                    }}
                                    underline="none"
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        disableElevation
                                    >
                                        {t('cartSummary.checkout.btn')}
                                    </Button>
                                </Link>
                            </div>
                            <Divider/>
                            <div className={classes.buttonWrapper}>
                                <Link
                                    component={RouterLink}
                                    to="/cart"
                                    onClick={() => {
                                        setState({miniCartOpen: false});
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        disableElevation
                                    >
                                        View Cart
                                    </Button>
                                </Link>

                                <Button
                                    style={{marginTop:'20px'}}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick = {() => setSavedCartDialog(true)}
                                    disableElevation
                                >   
                                    Save Current Cart
                                </Button>
                                <Button
                                    style={{marginTop:'20px'}}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick = {() => exportPdf()}
                                    disableElevation
                                >   
                                    Export PDF
                                </Button>
                            </div>
                        </>
                    ) : null}
                    {productToDelete ? (
                        <ConfirmationMessage
                            severity="error"
                            handleYes={() => handleDeleteItem(productToDelete.productId)}
                            handleNo={() => setProductToDelete(null)}
                        >
                            Are you sure you want to delete this item?
                        </ConfirmationMessage>
                    ) : null}
                </SwipeableDrawer>
            </div>

            <SaveNewCartDialog
                open={openSaveCartDialog}
                handleClose={handleCloseSaveCart}
                hasItems={data?.cart?.lines ? data?.cart?.lines.length > 0 : false}

              />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
        flexGrow: 1,
        minWidth: 300,
        overflow: 'auto',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        paddingRight: theme.spacing(1)
    },
    buttonWrapper: {
        padding: theme.spacing(2),
    },
    desktopButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    footer: {},
    items: {},
    stretch: {
        flexGrow: 1,
    },

    menuDrawer: {
        justifyContent: 'center',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 440,
        },
    },
    button: {
        width: '70%',
        margin: '0 auto',
    },
}));

export default MiniCart;
